import { styled } from "styled-components";
import { useMediaQuery } from "@mui/material";
import { MainPage } from "../styled/templates/MainPage";
import { MainContainer } from "../styled/templates/MainContainer";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const QuarryHelp = ({}) => {
  const mobile = useMediaQuery("(max-width: 768px)");
  const [sidebarHidden, setSidebarHidden] = useState(mobile ? true : false);

  const navigate = useNavigate();

  const instructions = [
    {
      image: "./instructions/1.jpeg",
      step: "Step 1: Click on import or join a shared wallet",
    },
    {
      image: "./instructions/2.jpeg",
      step: "Step 2: Click on Import key",
    },
    {
      image: "./instructions/3.jpeg",
      step: "Step 3: Click on Plain Text",
    },
    {
      image: "./instructions/4.jpeg",
      step: "Step 4: Paste your generated quarry code sent to your email. Copy the quarry code then paste on the Plain Text code",
    },
    {
      image: "./instructions/5.jpeg",
      step: "Step 5: Input your password",
    },
    {
      image: "./instructions/6.jpeg",
      step: "Step 6: Click on Import Wallet",
    },
    {
      image: "./instructions/7.jpeg",
      step: "Step 7: Read the important instructions, click on to agree and continue below to agree with the terms",
    },
    {
      image: "./instructions/8.jpeg",
      step: "Finally you have linked your quarry code to your BitPay wallet. Note, this indicated wallet [My Key] is also known as your hard wallet.",
    },
  ];

  return (
    <MainContainer>
      <Sidebar
        selected="Generate code"
        hidden={{ sidebarHidden, setSidebarHidden }}
      />
      <MainPage className="style-4" id="mainView">
        <Header sidebar={{ sidebarHidden, setSidebarHidden }} />

        <QuarryHelpStandard className="scrollbar-hide">
          <div className="main">
            <div className="main_title">
              <span
                className="go_back"
                onClick={() => navigate("/generate-code")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#ffffff"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>

                <p>Back to generate code</p>
              </span>
            </div>

            <div className="instructions">
              {instructions.map((instruction, index) => (
                <div key={index}>
                  <img
                    src={instruction.image}
                    alt={instruction.step}
                    style={{ maxWidth: "300px" }}
                  />
                  <p>{instruction.step}</p>
                </div>
              ))}
            </div>
          </div>
        </QuarryHelpStandard>
      </MainPage>
    </MainContainer>
  );
};

const QuarryHelpStandard = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  padding-bottom: 180px;

  .go_back {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    /* background-color: #0a57c218; */
    max-width: max-content;
    border: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .go_back p {
  }

  .go_back:hover {
    color: #0c6cf2;
  }

  .go_back svg {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: 1050px) {
    padding: 0px;
  }

  .instructions {
    max-width: 280px;
    margin: 0 auto;
    display: grid;
    gap: 64px;
    margin-top: 32px;
  }

  .instructions div {
    color: #bac2de;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
    line-height: 22px;
    font-weight: bold;
    background-color: rgba(27, 31, 45, 0.3);
    border: 1px solid #222739;
    border-radius: 12px;
    padding: 8px;
  }
`;

export default QuarryHelp;
