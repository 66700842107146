import { styled } from "styled-components";
import { SettingsFormStandard } from "../../../styled/forms/SettingsFormStandard";
import {
  DropDownIconOutlined,
  FormButton,
  OutlinedIconBoxWithButton,
} from "../../../styled/input/Input";
import { useContext, useEffect, useState } from "react";
import DepositModal from "../../../modals/DepositModal";
import EditDepositAddressModal from "../../../modals/EditDepositAddressModal";
import AddDepositAddressModal from "../../../modals/AddDepositAddressModal";
import { context } from "../../../context/context";
import CircularLoader from "../../../styled/loaders/CircularLoader";
import { db } from "../../../firebase/firebase";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { Skeleton } from "@mui/material";
import Toast from "../../../hooks/Toast";

const MiningSettings = () => {
  const { userData } = useContext(context);

  const [isLoading, setIsLoading] = useState(true);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const { miningSettings } = userData;

  const [isAutoMining, setIsAutoMining] = useState(false);
  const [isAutoRise, setIsAutoRise] = useState(false);

  //   miningSettings: ["Auto Mining", "Auto Rise"]

  useEffect(() => {
    if (miningSettings) {
      //   console.log("settings", withdrawalSettings);
      const settings = miningSettings;
      setIsAutoMining(settings.includes("Auto Mining") ? true : false);
      setIsAutoRise(settings.includes("Auto Rise") ? true : false);

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [miningSettings]);

  const [isSavingMiningSettings, setIsSavingMiningSettings] = useState(false);

  async function handleSaveMiningSettings() {
    setIsSavingMiningSettings(true);

    let miningList = [];

    if (isAutoMining) {
      miningList.push("Auto Mining");
    }

    if (isAutoRise) {
      miningList.push("Auto Rise");
    }

    const q = doc(db, "users", userData.id);
    try {
      await updateDoc(q, {
        miningSettings: miningList,
      });
      setIsSavingMiningSettings(false);
      setToastType("success");
      setToastMessage("Saved successfully");
      setOpenToast(true);
      // console.log("saved");
      // toast.success("Updated successfully.");
      // setIsEditing(false);
    } catch (error) {
      // console.log(error);
      setIsSavingMiningSettings(false);
      setToastType("error");
      setToastMessage("Failed to save. Please try again later");
      setOpenToast(true);
      // toast.error("Could not update. Please try again later");
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <MiningSettingsStandard>
        <SettingsFormStandard>
          <div className="header">
            <p className="title">Mining</p>
            <p className="subtext"> Manage your mining settings</p>
          </div>

          {isLoading ? (
            <div className="form">
              {/* Withdrawal Methods */}
              <div className="section">
                <div className="section_intro">
                  <p className="title">
                    {" "}
                    <Skeleton
                      variant="rounded"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "20%",
                      }}
                    />
                  </p>
                  <p className="subtext">
                    <Skeleton
                      variant="rounded"
                      sx={{
                        backgroundColor: "rgba(27, 31, 45)",
                        maxWidth: "60%",
                      }}
                    />
                  </p>
                </div>

                {/* Crypto */}
                <div className="form_item">
                  <Skeleton
                    variant="rounded"
                    height={80}
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      // maxWidth: "20%",
                    }}
                  />
                </div>

                {/* Bank */}
                <div className="form_item">
                  <Skeleton
                    variant="rounded"
                    height={80}
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      // maxWidth: "20%",
                    }}
                  />
                </div>

                {/* PayPal */}

                <div className="form_item">
                  <Skeleton
                    variant="rounded"
                    height={80}
                    sx={{
                      backgroundColor: "rgba(27, 31, 45)",
                      // maxWidth: "20%",
                    }}
                  />
                </div>

                {/* Skrill */}
              </div>
            </div>
          ) : (
            <div className="form">
              {/* Mining options */}
              <div className="section">
                <div className="section_intro">
                  <p className="title">Mining options</p>
                  <p className="subtext">Edit mining options</p>
                </div>

                {/* Auto Mining */}
                <div className="form_item">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #222739",
                      backgroundColor: "rgba(27, 31, 45, 0.3)",
                      borderRadius: "12px",
                      padding: "16px 24px",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <p
                        style={{
                          color: "#fff",
                          fontWeight: "500",
                          lineHeight: "28px",
                        }}
                      >
                        Auto Mining
                      </p>
                      <p
                        style={{
                          color: "#bac2de",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        Enable Auto Mining
                      </p>
                    </span>

                    <ToggleSwitch
                      className={isAutoMining && "toggled"}
                      onClick={() => setIsAutoMining(!isAutoMining)}
                    >
                      <div className="toggle"></div>
                    </ToggleSwitch>
                  </div>
                </div>

                {/* Auto Rise */}
                <div className="form_item">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #222739",
                      backgroundColor: "rgba(27, 31, 45, 0.3)",
                      borderRadius: "12px",
                      padding: "16px 24px",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <p
                        style={{
                          color: "#fff",
                          fontWeight: "500",
                          lineHeight: "28px",
                        }}
                      >
                        Auto Rise
                      </p>
                      <p
                        style={{
                          color: "#bac2de",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        Enable Auto rise
                      </p>
                    </span>

                    <ToggleSwitch
                      className={isAutoRise && "toggled"}
                      onClick={() => setIsAutoRise(!isAutoRise)}
                    >
                      <div className="toggle"></div>
                    </ToggleSwitch>
                  </div>
                </div>
              </div>

              <FormButton
                onClick={handleSaveMiningSettings}
                className={
                  isSavingMiningSettings
                    ? "form_button disabled"
                    : "form_button"
                }
              >
                {isSavingMiningSettings ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="24" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FormButton>
            </div>
          )}
        </SettingsFormStandard>
      </MiningSettingsStandard>
    </>
  );
};

const ToggleSwitch = styled.button`
  height: 24px;
  width: 48px;
  max-width: 48px;
  padding: 0;
  background-color: #222739;

  border-radius: 48px;
  display: flex;
  cursor: pointer;
  border: none;
  outline: none;
  /* margin: 0; */

  &.toggled {
    background-color: white;
  }

  .toggle {
    width: 22px;
    height: 22px;
    margin-left: 2px;
    border-radius: 100%;
    place-self: center;
    /* padding: 2px; */
    transition: all 300ms ease-in-out;
    background-color: #07080d;
  }

  &.toggled .toggle {
    transform: translateX(20px);
    margin-left: 4px;
  }
`;

const MiningSettingsStandard = styled.div`
  .form_item {
    margin-top: 24px;
  }

  .mini_form_item {
    margin-top: 12px;
  }
`;

export default MiningSettings;
