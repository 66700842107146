import { useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { FullButton, MiniAmountBoxFull, TextBox } from "../styled/input/Input";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import BillingItem from "../components/BillingItem";
import emailjs from "@emailjs/browser";
import { siteSettings } from "../static";
import { LargeDivider } from "../styled/forms/dividers";
import CircularLoader from "../styled/loaders/CircularLoader";
import Toast from "../hooks/Toast";

const SendEmailModal = ({ open, user }) => {
  const { sendEmail, setSendEmail } = open;
  const { userDetails, setUserDetails } = user;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  const [emailSubject, setEmailSubject] = useState("");
  function handleEmailSubject(e) {
    const value = e.target.value;

    if (value) {
      setEmailSubject(value);
    } else {
      setEmailSubject("");
    }
  }

  const [emailBody, setEmailBody] = useState("");
  function handleEmailBody(e) {
    const value = e.target.value;

    if (value) {
      setEmailBody(value);
    } else {
      setEmailBody("");
    }
  }

  const [isSending, setIsSending] = useState(false);
  async function sendUserEmail() {
    setIsSending(true);

    const params = {
      logo_link: `${siteSettings.ogLink}/logo.png`,
      broker_name: `${siteSettings.name}`,
      broker_to_link: `${siteSettings.link}`,
      to_name: userDetails.firstname,
      to_email: userDetails.email,
      subject: emailSubject,
      from_name: siteSettings.name,
      body: emailBody,
    };

    emailjs
      .send("service_vgkagi6", "template_tnf1h8o", params, "9IOr2_lHheTH7RW1k")
      .then((response) => {
        console.log(response);
        setIsSending(false);
        setToastType("success");
        setToastMessage("Email sent successfully");
        setOpenToast(true);
        setTimeout(() => {
          setSendEmail(false);
        }, 500);
      })
      .catch((error) => {
        setIsSending(false);
        setToastType("error");
        setToastMessage("Failed to send. Please try again later");
        setOpenToast(true);
      });
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={sendEmail}
        onClose={() => setSendEmail(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
          padding: "12px",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Send email</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setSendEmail(!sendEmail)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              {/* name */}
              <TextBox className="scrollbar-hide">
                <label htmlFor="address">User:</label>
                <br />
                <input
                  type="text"
                  placeholder={
                    userDetails?.firstname + " " + userDetails?.lastname
                  }
                  value={userDetails?.firstname + " " + userDetails?.lastname}
                  defaultValue={
                    userDetails?.firstname + " " + userDetails?.lastname
                  }
                  disabled
                />
              </TextBox>

              {/* email */}
              <TextBox className="scrollbar-hide">
                <label htmlFor="address">User email:</label>
                <br />
                <input
                  type="text"
                  placeholder={userDetails?.email}
                  value={userDetails?.email}
                  defaultValue={userDetails?.email}
                  disabled
                />
              </TextBox>

              {/* email subject */}
              <TextBox className="scrollbar-hide">
                <label htmlFor="address">Email subject:</label>
                <br />
                <input
                  type="text"
                  placeholder=""
                  onChange={handleEmailSubject}
                />
              </TextBox>

              {/* body */}
              <TextBox className="scrollbar-hide">
                <label htmlFor="address"> Email body:</label>
                <br />
                <textarea
                  type="text"
                  cols="30"
                  rows="5"
                  onChange={handleEmailBody}
                  //   placeholder={currPhrase || `Your ${selected} seed phrase`}
                ></textarea>
              </TextBox>
            </div>

            <div className="bottom">
              <FullButton
                onClick={sendUserEmail}
                disabled={isSending}
                className={isSending && "disabled"}
              >
                {isSending ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Send</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const SliderStandard = styled.div`
  display: flex;
  list-style: none;
  height: 250px;
  overflow-x: scroll;
  padding: 32px 24px;
  flex: 0 0 600px;
  margin: 0 auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #fff3;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: #fff3;
  }

  div {
    flex: 0 0 100px;
    background: var(--accent);
    margin: 0 20px 0 0;
  }

  div:last-of-type {
    margin: 0;
  }

  .slide {
    position: relative;
    white-space: nowrap;
    /* margin-left: 24px; */
    cursor: pointer;
  }

  .slide img {
    border-radius: 12px;
    /* border: 1px solid red; */
    padding: 4px;
    border: 1px solid transparent;
    transition: border-color 0.3s ease-in-out;
  }

  .slide img.selected {
    border: 1px solid red;
    /* border-radius: 12px; */
    /* padding: 4px; */
  }
`;

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .go_back {
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    /* background-color: #0a57c218; */
    max-width: max-content;
    border: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .go_back p {
  }

  .go_back:hover {
    color: #0c6cf2;
  }

  .go_back svg {
    width: 18px;
    height: 18px;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    margin-top: 32px;
    font-family: "Inter";
  }

  .bottom button:hover {
    background-color: #0a57c2;
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }

  .slide {
    color: white;
    /* margin-left: 30px; */
  }

  .slider {
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    gap: 12px;
    overflow-y: hidden;
  }

  .slide img {
    /* margin-left: 12px; */
    aspect-ratio: 4.135/ 5.845;
    width: 100%;
  }
`;

export default SendEmailModal;
