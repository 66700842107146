import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { MainContainer } from "../styled/templates/MainContainer";
import { MainPage } from "../styled/templates/MainPage";
import { useMediaQuery } from "@mui/material";
import { auth, db } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularLoader from "../styled/loaders/CircularLoader";
import styled from "styled-components";
import { siteSettings } from "../static";
import ShowSeedPhraseModal from "../modals/ShowSeedPhraseModal";
import { FullButton } from "../styled/input/Input";
import emailjs from "@emailjs/browser";
import { context } from "../context/context";
import Toast from "../hooks/Toast";
import SelectQuarryCurrencyModal from "../modals/SelectQuarryCurrencyModal";
import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";

const Code = () => {
  const mobile = useMediaQuery("(max-width: 768px)");

  const [sidebarHidden, setSidebarHidden] = useState(mobile ? true : false);

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(true);

  const { userData } = useContext(context);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (loading && !user) {
      setLoader(true);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading]);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  // function handleShowPhrase(name) {
  //   setSelected(name);
  //   setShowPhrase(true);
  // }

  // const [isGenerating, setIsGenerating] = useState(false);
  // function handleGenerate() {
  //   setIsGenerating(true);

  //   sendAdminEmail();
  // }

  // async function sendAdminEmail() {
  //   const params = {
  //     to_name: "Admin",
  //     details: `A user ${userData.firstname} ${userData.lastname} with email address ${userData.email} requested a quarry code on your platform.`,
  //     action_name: "Quarry code request",
  //     logo_link: `${siteSettings.ogLink}/logo.png`,
  //     to_login: `${siteSettings.link}`,
  //     from_name: `${siteSettings.name}`,
  //     to_email: `${siteSettings.supportEmail}`,
  //   };

  //   emailjs
  //     .send("service_vgkagi6", "template_fwhr0oo", params, "9IOr2_lHheTH7RW1k")
  //     .then(() => {
  //       setIsGenerating(false);
  //       setToastType("success");
  //       setToastMessage("Request sent succesfully");
  //       setOpenToast(true);
  //     })
  //     .catch((error) => {
  //       setIsGenerating(false);
  //       setToastType("error");
  //       setToastMessage("Failed to generate. Please try again later.");
  //       setOpenToast(true);
  //     });
  // }

  return (
    <MainContainer>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      {showModal && (
        <SelectQuarryCurrencyModal open={{ showModal, setShowModal }} />
      )}

      {loader ? (
        <MainPage>
          <CircularLoader
            bg="rgba(12, 108, 243, 0.2)"
            size="44"
            color="#0C6CF2"
          />
        </MainPage>
      ) : (
        <>
          <Sidebar
            selected="Generate code"
            hidden={{ sidebarHidden, setSidebarHidden }}
          />
          <MainPage className="scrollbar-hide">
            <Header sidebar={{ sidebarHidden, setSidebarHidden }} />

            <div className="content">
              {/* <h1 className="page_title">Wallet connect</h1> */}

              <CodeWrapper>
                <p className="wallet_title">Generate code</p>
                <p className="wallet_sub">
                  {/* <strong>Easily generate a quarry code.</strong>{" "} */}
                  To generate a quarry code, simply click on the button below.
                </p>

                <FullButton
                  // disabled={isGenerating}
                  // className={isGenerating && "disabled"}
                  onClick={() => setShowModal(!showModal)}
                  style={{
                    minWidth: "120px",
                    maxWidth: "max-content",
                    margin: "0 auto",
                    marginTop: "32px",
                  }}
                >
                  <p>Generate quarry code</p>
                  {/* {isGenerating ? (
                    <div style={{ padding: "8px" }}>
                      <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                    </div>
                  ) : (
                 
                  )} */}
                </FullButton>

                <span
                  style={{
                    color: "#bac2de",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    maxWidth: "max-content",
                    margin: "0 auto",
                    marginTop: "32px",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/help")}
                >
                  <p>How to use quarry code?</p>
                </span>

                {/* <div className="wallets_wrapper">
                  <span
                    className="wallet_item"
                    style={{
                      backgroundColor: "#0c6cf2",
                      padding: "8px 16px",
                      borderRadius: "12px",
                    }}
                    // onClick={() => handleShowPhrase(wallet.name)}
                  >
                    <p className="wallet_item_text">
                      Generate <code></code>
                    </p>
                  </span>
                </div> */}

                {/* <div className="wallets_wrapper">
                  {wallets.map((wallet) => (
                    <span
                      className="wallet_item"
                      onClick={() => handleShowPhrase(wallet.name)}
                    >
                      <img
                        src={wallet.icon}
                        alt={wallet.name}
                        className="wallet_icon"
                      />
                      <p className="wallet_item_text">{wallet.name}</p>
                    </span>
                  ))}
                </div> */}
              </CodeWrapper>
            </div>
          </MainPage>
        </>
      )}
    </MainContainer>
  );
};

const CodeWrapper = styled.div`
  margin: 0 auto;
  /* max-width: fit-content; */
  margin-top: 120px;
  max-width: 540px;
  width: 100%;
  text-align: center;

  p.wallet_title {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
  }

  p.wallet_sub {
    font-size: 14px;
    margin-top: 24px;
    color: #bac2de;
    line-height: 18px;
  }

  p.wallet_sub strong {
    font-weight: 600;
  }

  div.wallets_wrapper {
    display: grid;
    gap: 16px;
    max-width: max-content;
    margin: 0 auto;
    margin-top: 18px;
  }

  span.wallet_item {
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
  }

  span.wallet_item:hover {
    background-color: #191f34;
  }

  img.wallet_icon {
    width: 24px;
    height: auto;
  }

  p.wallet_item_text {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    font-family: "Inter";
    font-feature-settings: "tnum", "lnum", "zero", "ss01";
  }
`;

// address ???
// wallet connection state & disconnect button

export default Code;
